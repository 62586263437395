import { render, staticRenderFns } from "./UaRatingsModal.vue?vue&type=template&id=55236ab6&scoped=true&"
import script from "./UaRatingsModal.vue?vue&type=script&lang=js&"
export * from "./UaRatingsModal.vue?vue&type=script&lang=js&"
import style0 from "./UaRatingsModal.vue?vue&type=style&index=0&id=55236ab6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55236ab6",
  null
  
)

export default component.exports